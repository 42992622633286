import React, { useContext, useState } from "react";
import axios from "axios";
import tingle from "tingle.js";

import {
	MDBBtn,
	MDBBadge,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	MDBCard,
	MDBCardBody,
	MDBCardImage,
	MDBCardTitle,
	MDBCardText,
	MDBRow,
	MDBCol,
} from "mdbreact";

import PropTypes from "prop-types";

export default function CardDisciplinaPaliativo({ CodComposicaoCurricular }) {
	CardDisciplinaPaliativo.propTypes = {
		CodComposicaoCurricular: PropTypes.string.isRequired,
	};

	let curriculo = {
		NomeCurso: "",
		Linha1: "",
		Linha2: "",
		TempoConclusao: "",
		urlComposicao: "",
	};

	const partes = String(CodComposicaoCurricular).split(",");
	const palavrasSeparadas = [];
	const tamanhoDaParte = 5;

	for (let i = 0; i < partes.length; i++) {
	palavrasSeparadas.push(partes[i]);
	}

	
		
			curriculo.NomeCurso = palavrasSeparadas[0];
			curriculo.Linha1 =
			palavrasSeparadas[1];
			curriculo.Linha2 = palavrasSeparadas[2];
			curriculo.TempoConclusao =
			palavrasSeparadas[3];
			curriculo.urlComposicao =
				palavrasSeparadas[4];
	
	const cards = [];

	// Verifique se está no ambiente do navegador antes de acessar window
	const larguraMinimaDaTela = typeof window !== 'undefined' ? window.innerWidth : 0;

	// Verifique se a largura da tela é menor que o ponto de corte
	const deveAlinharDireita = larguraMinimaDaTela < 600;

 	const classesBtn = `m-2 ${deveAlinharDireita ? 'd-flex justify-content-center' : 'd-flex justify-content-end'}`;

  for (let i = 0; i < palavrasSeparadas.length; i += 5) {
    const card = (
      <MDBCard className="mb-4" key={i}>
        <MDBRow className="mx-4 pt-4">
          <MDBCol md="12">
            <h5 className="font-weight-bold pb-0">
              {palavrasSeparadas[i]}
              <br />
              <MDBBadge className="mt-2" color="primary" size="2x">
                {palavrasSeparadas[i + 1]}
              </MDBBadge>
            </h5>
            <p className="small py-2">
              {palavrasSeparadas[i + 2]}
              <br />
              {palavrasSeparadas[i + 3]}
            </p>
          </MDBCol>
        </MDBRow>
        <div className={classesBtn}>
          <MDBBtn
            className="m2"
            color="primary"
            href={palavrasSeparadas[i + 4]}
            target="_blank"
          >
            <i className={"fas fa-chevron-circle-right mr-1"}></i> Veja a Estrutura Curricular
          </MDBBtn>
        </div>
      </MDBCard>
    );

    cards.push(card);
  }

	return (
		<div>
		{cards}
	  </div>
		
	);
}
